<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p><strong>*Our Kodi addon has been updated, currently there isn't any "My List".</strong></p>
								<p>
									<strong>After updating your Kodi, please reboot your Kodi or device.</strong><br />Download and install Kodi 18:
									<a href="https://kodi.tv/download">DOWNLOAD</a><br /><br />
								</p>
								<p>
									Once You run Kodi, go to Setting &gt; File Manager &gt; Add Source (our Repository)<br /><br />And fill in according to the picture:<br />A.
									Instead of fill in “<a :href="$store.state.brandInfo.repoURL">{{ $store.state.brandInfo.repoURL }}”</a>.<br />B. Enter the name
									{{ $store.state.brandInfo.appName }} .<br />C. Click OK.<br /><br /><img
										src="../../../assets/images/DmAGNwIAa2Fe-8KcNTn69yxT7iAAw60Wag.png?1563842178"
										class="fr-fic fr-dii"
										data-filelink="../../../assets/images/DmAGNwIAa2Fe-8KcNTn69yxT7iAAw60Wag.png?1563842178"
										data-fileid="48002056974"
										data-uniquekey="1563842172193"
										style="width: 100%"
									/><br /><br />
								</p>
								<p>
									Go to the main menu and go to “Add-ons”Then on the top left side click on the box<br /><br /><img
										src="../../../assets/images/KM-_gxuIRJOerFsLBVyIp9CYzHoEs8sU9Q.png?1563842178"
										class="fr-fic fr-dii"
										data-filelink="../../../assets/images/KM-_gxuIRJOerFsLBVyIp9CYzHoEs8sU9Q.png?1563842178"
										data-fileid="48002056972"
										data-uniquekey="1563842172193"
										style="width: 100%"
									/><br /><br />
								</p>
								<p>Click on “Install from zip File”<br /><br /></p>
								<p>
									A message will appear, click on “Settings”<br /><br /><img
										src="../../../assets/images/Z2d0WLN_t4XW_m3KoTnBP_ujYm_GCCc0Cw.png?1563842177"
										class="fr-fic fr-dii"
										data-filelink="../../../assets/images/Z2d0WLN_t4XW_m3KoTnBP_ujYm_GCCc0Cw.png?1563842177"
										data-fileid="48002056969"
										data-uniquekey="1563842172193"
										style="width: 100%"
									/><br /><br />
								</p>
								<p>
									Make sure on the left side pane is set to “Add-ons” then on the right side click on “Unknown Sources”<br />(in order to allow installing our
									repository)<br />THEN CLICK <strong>“YES”</strong>.<br /><br /><img
										src="../../../assets/images/z7nJA7NzpVEnoZeOWDj3qNXYMUNUzNI-Hw.png?1563842178"
										class="fr-fic fr-dii"
										data-filelink="../../../assets/images/z7nJA7NzpVEnoZeOWDj3qNXYMUNUzNI-Hw.png?1563842178"
										data-fileid="48002056976"
										data-uniquekey="1563842172193"
										style="width: 100%"
									/><br /><br />
								</p>
								<p>Once you click “YES”, go one screen back and click again on “Install from zip file”<br /><br /></p>
								<p>
									Choose and click on {{ $store.state.brandInfo.displayName }} (should be the first on the list, it is based on the name we gave earlier)<br /><br />
								</p>
								<p>Choose and click on “repo.zip” and wait for it to install the repository (should take several seconds)<br /><br /></p>
								<p>
									Once done, click on “Install from repository” &gt; “{{ $store.state.brandInfo.displayName }} Repo” &gt; “Video Add-ons” &gt; “{{
										$store.state.brandInfo.displayName
									}}
									”<br /><br />
								</p>
								<p>
									Then it will ask you 2 versions:<br />{{ $store.state.brandInfo.displayName }} 4.1.9 or
									<strong>4.2.1(or higher) MAKE SURE TO CHOOSE 4.2.1 ({{ $store.state.brandInfo.displayName }} - Kodi 18)</strong><br /><strong><br /></strong
									><img
										src="../../../assets/images/13_jmTL-IjaMRK0ZSN86oZL4PO7PIe-i8g.png?1591912103"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48044190753"
									/>
								</p>
								<p>Then click “Install”</p>
								<p>
									<br /><img
										src="../../../assets/images/R6UDg_SJnekwKpgdznKrXKWu5jn5fEWJpw.png?1591912199"
										class="fr-fic fr-dii"
										data-fileid="48002056980"
										style="
											box-sizing: border-box;
											border: 0px;
											vertical-align: bottom;
											max-width: calc(100% - 10px);
											position: relative;
											cursor: pointer;
											display: inline-block;
											float: none;
											margin-left: 5px;
											margin-right: 5px;
											color: rgb(0, 0, 0);
											font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
											font-size: 13px;
											font-style: normal;
											font-variant-ligatures: normal;
											font-variant-caps: normal;
											font-weight: 400;
											letter-spacing: normal;
											orphans: 2;
											text-align: start;
											text-indent: 0px;
											text-transform: none;
											white-space: normal;
											widows: 2;
											word-spacing: 0px;
											-webkit-text-stroke-width: 0px;
											text-decoration-style: initial;
											text-decoration-color: initial;
											width: 708px;
										"
										data-attachment="[object Object]"
										data-id="48044190840"
									/>
								</p>
								<p>
									On the next screen click on OK (to install all those dependencies)<br />Now it will install our addon, it might take up to a minute (depends on
									your device).
								</p>
								<p>
									<br /><img
										src="../../../assets/images/EPv3yZ-WL7YIGVcqQusJ1ODUl_MWwqLpSA.png?1591912246"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48044190908"
									/>
								</p>
								<p>Wait for it to finish installing</p>
								<p><br /></p>
								<p>
									Once the installation has finished a pop up will appear asking you “Are you a Member” if you are, click yes.<br />If not, go ahead and get a
									trial from our website: <a :href="`${$store.state.brandInfo.siteLink}/#trial`">{{ $store.state.brandInfo.siteLink }}/#trial</a><br /><br />
								</p>
								<p>Enter in your {{ $store.state.brandInfo.displayName }} registered Email Address.<br /><br /></p>
								<p>Enter in your {{ $store.state.brandInfo.displayName }} account password.<br /><br /></p>
								<p>Then it will ask you if you want to use {{ $store.state.brandInfo.displayName }} guide.<br /><br /></p>
								<p>If you have your own addon for TV-Guide click no. Otherwise, click YES.<br /><br /></p>
								<p>Then click OK on the pop-up, and lets exit Kodi so Kodi will set all its settings.<br /><br /></p>
								<p>Make sure you exit clean Kodi.<br /><br /></p>
								<p>
									Go to the main menu and click on the shut-down button and click on “Exit”<br /><br /><img
										src="../../../assets/images/os1lcSH1YGdr5uW7LcrnIBEof8eiXdIUpw.png?1563842178"
										class="fr-fic fr-dii"
										data-filelink="../../../assets/images/os1lcSH1YGdr5uW7LcrnIBEof8eiXdIUpw.png?1563842178"
										data-fileid="48002056973"
										data-uniquekey="1563842172193"
										style="width: 100%"
									/><br /><br />
								</p>
								<p>*it might take a minute or two for Kodi to exit, give it some time. Once it is done open Kodi again.<br /><br /></p>
								<p>
									To enter our addon, click on the left side panel on “Add-ons” Then click on {{ $store.state.brandInfo.displayName }}.<br />(To be sure your
									account is correct to go to Tools(in our addon) and see your account info,<br />if you don’t see account info,
									<strong>click on Login</strong>).<br /><br /><img
										src="../../../assets/images/R9eIYTP5yVpKRerXz9uWtT-pZgrtKE2oRA.png?1563842179"
										class="fr-fic fr-dii"
										data-filelink="../../../assets/images/R9eIYTP5yVpKRerXz9uWtT-pZgrtKE2oRA.png?1563842179"
										data-fileid="48002056979"
										data-uniquekey="1563842172193"
										style="width: 100%"
									/><br /><br />
								</p>
								<p>
									To open the TV-Guide on Kodi 18 you can do it only via:<br />Main menu: Left side pane “TV” and then “Channels”<br /><br /><img
										src="../../../assets/images/fweQdwFMl8buAkwZfUSqT97y9kIwaMQYPQ.png?1563842179"
										class="fr-fic fr-dii"
										data-filelink="../../../assets/images/fweQdwFMl8buAkwZfUSqT97y9kIwaMQYPQ.png?1563842179"
										data-fileid="48002056978"
										data-uniquekey="1563842172193"
										style="width: 100%"
									/>
								</p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'howDoIAddToKodi',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
